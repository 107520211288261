import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "@mui/material/IconButton";

import StyledIconButton from "./IconButton";
import "swiper/css";
import { Box, CardContent, Divider } from "@mui/material";

import formatCurrency from "../utils/currencyFormat";
import dayjs from "../utils/dayjs";
import { sum } from "lodash-es";
import { getFormattedDate } from "../utils/getFormattedDate";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { formattedTranslationValue } from "../utils/formatted-translation-value";
import { isHTMLEmpty } from "../utils/isHtml";
import { calculateIsDarkColor } from "../utils/color";

export const isOutOfStock = (pp, pkg, cart) => {
	if (!Boolean(pp) || !Boolean(pkg)) return true;
	const stock = pkg.stock;
	const prevOrdsAmount = sum(
		pkg.pricePeriods.flatMap((pp) =>
			pp.orderPricePeriods.map((opp) => opp.amount)
		)
	);

	const curOrdAmount =
		cart.find((item) => item.id === pp?.id)?.quantity ?? 0 + 1;
	const totalAmount = prevOrdsAmount + curOrdAmount;
	return (stock && stock <= totalAmount) || pkg?.noStock;
};

const PackageHorizontal = ({
	pkg,
	currency,
	event,
	language,
	color,
	onCartUpdate,
	cart,
	setCartOpen,
	free,
}) => {
	const intl = useIntl();
	const [activePeriod, setActivePeriod] = useState();
	const [defaultPeriod, setDefaultPeriod] = useState();

	const [open, setOpen] = useState(false);
	const [selectedPkg, setSelectedPkg] = useState();

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setSelectedPkg(null);
	};

	const findActivePrice = (data) => {
		const now = dayjs().startOf("day");
		const activePeriod = data.find((item) => {
			const startDate = item.startDate ? dayjs.utc(item.startDate) : null;
			const endDate = item.endDate ? dayjs.utc(item.endDate) : null;
			if (startDate && endDate) {
				const isStartDateValid = now >= startDate;
				const isEndDateValid = now <= endDate;
				return item.status && isStartDateValid && isEndDateValid;
			}
			return false;
		});

		return activePeriod || data.find((item) => item.isDefault);
	};

	useEffect(() => {
		const findActivePrice = (data) => {
			const now = dayjs().startOf("day");
			const activePeriod = data.find((item) => {
				const startDate = item.startDate ? dayjs.utc(item.startDate) : null;
				const endDate = item.endDate ? dayjs.utc(item.endDate) : null;
				if (startDate && endDate) {
					const isStartDateValid = now >= startDate;
					const isEndDateValid = now <= endDate;
					return item.status && isStartDateValid && isEndDateValid;
				}
				return false;
			});

			return activePeriod || data.find((item) => item.isDefault);
		};

		if (pkg && pkg.pricePeriods) {
			const price = findActivePrice(pkg.pricePeriods);
			setActivePeriod(price);
			setDefaultPeriod(pkg.pricePeriods.find((pp) => pp.isDefault));
		}
	}, [pkg]);

	const addToCart = (per, pkg) => {
		onCartUpdate({
			id: per.id,
			description: `${pkg.description} (${per.description})`,
			price: free ? 0 : per.price,
			package: pkg,
			stock: pkg.stock,
		});
	};
	return (
		<>
			<Box
				className="widget-block--form--container--item widget-block--horizontal"
				sx={{ position: "relative" }}
			>
				{pkg.isPopular && (
					<Box className="widget-block--form--container--item--popular--containerHorizon">
						<Box
							className="widget-block--form--container--item--popular--container--discountTag"
							sx={{ background: color }}
						></Box>
						<Typography
							variant="span"
							className="widget-block--form--container--item--popular--container--title"
						>
							<FormattedMessage id="populer" />
						</Typography>
					</Box>
				)}
				<Box className="widget-block--form--cart--container--horizontal--items">
					{formattedTranslationValue(pkg.translations, "title", language) && (
						<Box
							className="widget-block--form--cart--container--horizontal--header"
							sx={{
								background: color ?? "#e94c4e",
							}}
						>
							<Typography
								variant="span"
								sx={{ color: "#FFF" }}
								className="widget-block--form--cart--container--horizontal--title"
							>
								{formattedTranslationValue(pkg.translations, "title", language)}
							</Typography>
						</Box>
					)}
					<Box className="widget-block--form--cart--container--horizontal--items--header">
						<Typography
							variant="span"
							className="widget-block--form--cart--container--horizontal--items--header--title"
						>
							{formattedTranslationValue(pkg.translations, "name", language)}
						</Typography>
						<Box>
							<Box className="widget-block--form--cart--container--horizontal--items--header--right">
								{activePeriod?.isDefault && (
									<Typography
										variant="span"
										fontSize={"1.25em"}
										fontWeight="700"
										className="widget-block--form--cart--container--horizontal--items--header--right--price"
									>
										{formatCurrency(activePeriod?.price, currency, intl, free)}
									</Typography>
								)}
								{isOutOfStock(activePeriod, pkg, cart) ? (
									<Button
										variant="contained"
										disabled
										startIcon={<DoNotDisturbIcon />}
										className="no-toggle"
										sx={{
											fontSize: "0.875em",
										}}
									>
										<FormattedMessage id="no-stock" />
									</Button>
								) : (
									<Button
										variant="contained"
										startIcon={<ShoppingCartIcon />}
										sx={{
											backgroundColor: color,
											color: calculateIsDarkColor(color) ? "#fff" : "#000",
											marginRight: "8px",
											"&:hover": {
												opacity: 0.8,
												backgroundColor: calculateIsDarkColor(color)
													? "#fff"
													: "#000",
												color: !calculateIsDarkColor(color) ? "#fff" : "#000",
											},
											fontSize: "0.875em",
										}}
										onClick={() => {
											addToCart(activePeriod, pkg);
											setCartOpen(true);
										}}
										className="no-toggle"
									>
										<FormattedMessage id="buy" />
									</Button>
								)}
							</Box>
						</Box>
					</Box>
					<CardContent
						sx={{
							py: 2,
							flexGrow: 1,
						}}
					>
						{formattedTranslationValue(
							pkg.translations,
							"description",
							language
						) && (
							<Box>
								<Box
									className="widget-block--form--modal--description"
									dangerouslySetInnerHTML={{
										__html: formattedTranslationValue(
											pkg.translations,
											"description",
											language
										),
									}}
								/>
							</Box>
						)}

						{pkg.pricePeriods &&
							pkg.pricePeriods.length > 0 &&
							!activePeriod?.isDefault && (
								<Box className="widget-block--form--cart--container--horizontal--items--price-periods">
									<Box className="widget-block--form--cart--container--horizontal--items--price-periods--left">
										<Typography
											variant="span"
											sx={{ fontSize: 16 }}
											textAlign="center"
											fontWeight="600"
										>
											{activePeriod?.description}
										</Typography>
										{!activePeriod?.isDefault && (
											<>
												<Box display="flex" alignItems="center" gap={1}>
													<Typography variant="span" sx={{ fontSize: 12 }}>
														{getFormattedDate(activePeriod?.startDate)}
													</Typography>
													<ChevronRightIcon size={20} />
													<Typography variant="span" sx={{ fontSize: 12 }}>
														{getFormattedDate(activePeriod?.endDate)}
													</Typography>
												</Box>
											</>
										)}
									</Box>
									<Box className="widget-block--form--cart--container--horizontal--items--price-periods--right">
										{!activePeriod?.isDefault && (
											<Typography
												variant="span"
												color={"error.main"}
												fontSize={"0.85rem"}
												sx={{
													position: "relative",
													display: "inline-block",
													paddingRight: "1em",
													"::before": {
														content: '""',
														position: "absolute",
														left: 0,
														bottom: 0,
														width: "90%",
														height: "1px",
														backgroundColor: "error.main",
														transform: "rotate(-20deg)",
														transformOrigin: "left bottom",
													},
												}}
											>
												{formatCurrency(
													defaultPeriod?.price,
													currency,
													intl,
													free
												)}
											</Typography>
										)}
										{pkg.pricePeriods && pkg.pricePeriods.length > 0 && (
											<Typography
												variant="span"
												fontSize={"1.25em"}
												fontWeight="700"
											>
												{formatCurrency(
													activePeriod?.price,
													currency,
													intl,
													free
												)}
											</Typography>
										)}
									</Box>
								</Box>
							)}
					</CardContent>
				</Box>
			</Box>

			{/* Children Packages */}
			{pkg.children.length > 0 && (
				<Box className="widget-block--form--cart--container--horizontal--items">
					<Box className="widget-block--form--cart--container--horizontal--items--children">
						<Box className="widget-block--form--cart--container--horizontal--items--children--header">
							<Typography
								variant="span"
								className="widget-block--form--cart--container--horizontal--items--children--header--title"
							>
								{formattedTranslationValue(
									pkg.translations,
									"childrenGroupTitle",
									language
								)}
							</Typography>
						</Box>
						<Box className="widget-block--form--cart--container--horizontal--items--children--item-container">
							{pkg.children.map((p) => {
								const childActivePeriod = findActivePrice(p.pricePeriods);
								return (
									<Box
										className="widget-block--form--cart--container--horizontal--items--children--item-container--item"
										key={p.id}
									>
										<Box className="">
											<Typography className="">
												{formattedTranslationValue(
													p.translations,
													"name",
													language
												)}
											</Typography>
											<Typography
												variant="body1"
												className=""
												sx={{
													fontWeight: 700,
												}}
											>
												{formatCurrency(
													childActivePeriod?.price,
													currency,
													intl,
													free
												)}
											</Typography>
										</Box>
										<Box>
											{!isHTMLEmpty(
												formattedTranslationValue(
													p.translations,
													"description",
													language
												)
											) && (
												<IconButton
													aria-label="details"
													onClick={() => {
														setSelectedPkg(p);
														handleOpen();
													}}
												>
													<SearchIcon />
												</IconButton>
											)}
											{isOutOfStock(childActivePeriod, p, cart) ? (
												<StyledIconButton disabled variant="contained">
													<DoNotDisturbIcon />
												</StyledIconButton>
											) : (
												<Button
													variant="contained"
													startIcon={<ShoppingCartIcon />}
													sx={{
														backgroundColor: color,
														color: calculateIsDarkColor(color)
															? "#fff"
															: "#000",
														"&:hover": {
															opacity: 0.8,
															backgroundColor: calculateIsDarkColor(color)
																? "#fff"
																: "#000",
															color: !calculateIsDarkColor(color)
																? "#fff"
																: "#000",
														},
														fontSize: "0.875em",
													}}
													onClick={() => {
														addToCart(childActivePeriod, p);
														setCartOpen(true);
													}}
													className="no-toggle"
												>
													<FormattedMessage id="buy" />
												</Button>
												// <IconButton
												//     aria-label="add cart"
												//     sx={{ color: color }}
												//     onClick={() => {
												//         addToCart(childActivePeriod, p);
												//         setCartOpen(true);
												//     }}
												// >
												//     <ShoppingCartIcon />
												// </IconButton>
											)}
										</Box>
									</Box>
								);
							})}
						</Box>
					</Box>
				</Box>
			)}

			{open && (
				<Modal open={open} aria-labelledby="modal-title" onClose={handleClose}>
					<Box className="widget-block--form--modal">
						<Box sx={{ textAlign: "center" }}>
							<IconButton
								onClick={handleClose}
								sx={{
									position: "absolute",
									top: 8,
									right: 8,
									cursor: "pointer",
								}}
							>
								<CloseIcon />
							</IconButton>
							<Typography
								variant="h6"
								sx={{ fontWeight: "bold", pb: "1em", color: color }}
							>
								{formattedTranslationValue(
									selectedPkg.translations,
									"name",
									language
								)}
							</Typography>
						</Box>
						<Divider />
						<Box className="widget-block--form--modal--details--container">
							<Box
								className="widget-block--form--modal--description"
								dangerouslySetInnerHTML={{
									__html: formattedTranslationValue(
										selectedPkg.translations,
										"description",
										language
									),
								}}
							/>
						</Box>
						<Box className="widget-block--form--modal--details--close">
							<Button
								startIcon={<CloseIcon sx={{ color: color }} />}
								onClick={handleClose}
								variant="outlined"
								sx={{
									color: "#000",
									borderColor: color,
									"&:hover": {
										opacity: 0.8,
									},
								}}
							>
								<FormattedMessage id="close" />
							</Button>
							<Button
								variant="contained"
								startIcon={<ShoppingCartIcon />}
								sx={{
									backgroundColor: color,
									color: calculateIsDarkColor(color) ? "#fff" : "#000",
									"&:hover": {
										opacity: 0.8,
										backgroundColor: calculateIsDarkColor(color)
											? "#fff"
											: "#000",
										color: !calculateIsDarkColor(color) ? "#fff" : "#000",
									},
									fontSize: "0.875em",
								}}
								onClick={() => {
									addToCart(activePeriod, pkg);
									setCartOpen(true);
									setOpen(false);
								}}
								className="no-toggle"
							>
								<FormattedMessage id="buy" />
							</Button>
						</Box>
					</Box>
				</Modal>
			)}
		</>
	);
};

export default PackageHorizontal;
