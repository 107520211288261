import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
	error: null,
	loading: true,
	speakers: null,
};

export const getSpeakers = createAsyncThunk(
	"speakers/getAll",
	async ({ organiserID, eventID, language, topics }) => {
		const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
		const REACT_APP_ENV = process.env.REACT_APP_ENV;
		const REACT_APP_API_URL_TEST = process.env.REACT_APP_API_URL_TEST;
		try {
			const baseUrl =
				REACT_APP_ENV === "prod" ? REACT_APP_API_URL : REACT_APP_API_URL_TEST;

			let url = `${baseUrl}/api/speaker/public/${organiserID}/${eventID}/${language}`;

			// Eğer topics varsa, URL'e ekle
			if (topics && topics.length > 0) {
				const encodedTopics = encodeURIComponent(topics); // güvenli olması için encode et
				url += `?topics=${encodedTopics}`;
			}

			const res = await axios({
				method: "get",
				url: url,
				headers: {
					"Content-Type": "application/json",
				},
			});
			return [topics, res?.data?.speakers];
		} catch (error) {
			throw new Error("Bir Hata Oluştu: Get all speakers");
		}
	}
);

export const speakersSlice = createSlice({
	name: "speakers",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getSpeakers.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getSpeakers.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.speakers = {
					...state.speakers,
					[action.payload[0]]: action.payload[1],
				};
				console.log(state.speakers);
			})
			.addCase(getSpeakers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export default speakersSlice.reducer;
