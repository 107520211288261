import { configureStore } from "@reduxjs/toolkit";
import programReducer from "./slices/program";
import speakerReducer from "./slices/speakers";

export const store = configureStore({
	reducer: {
		program: programReducer,
		speakers: speakerReducer,
	},
});
