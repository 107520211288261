{
	"populer": "Popüler",
	"buy": "Sepete Ekle",
	"detail": "Detaylar",
	"no-stock": "Tükendi",
	"optional": "Günlük",
	"close": "Kapat",
	"speaker": "Konuşmacılar",
	"modalSpeakerTitle": "Konuşmacı",
	"speakerDetail": "Konuşmacı Detayı",
	"program": "Program",
	"showAll": "Tümünü Göster",
	"selectedSession": "Seçili Oturumlar",
	"searchSpeakersSession": "Oturumlarda & Konuşmacı ara...",
	"searchSpeaker": "Konuşmacı Arama...",
	"notFoundSpeaker": "Aradığınız kriterlerde uygun kişi bulunamadı!",
	"cart": "Sepet",
	"price": "Fiyat",
	"quantity": "Adet",
	"total": "Toplam: ",
	"goToCart": "Sepete Git",
	"emptyCart": "Sepetiniz Boş",
	"free": "Ücretsiz",
	"modalSessionTitle": "Oturum",
	"programNotFound": "Program henüz eklenmemiş veya etkinlik aktif edilmemiş!",
	"faq": "Sıkça Sorulan Sorular",
	"my-ticket": "Biletlerim"
}
