// To be used for date and time formatting
export const getLocale = () =>
	navigator.languages && navigator.languages.length
		? navigator.languages[0]
		: navigator.language;

// Determines website language
export const getLocaleConfig = () =>
	navigator.languages && navigator.languages.length
		? navigator.languages[0].split("-")[0] !== "tr"
			? "en"
			: "tr"
		: navigator.language.split("-")[0] !== "tr"
		? "en"
		: "tr";

const formatMap = {
	"day-month-year": "DD/MM/YYYY",
	"month-day-year": "MM/DD/YYYY",
	"year-month-day": "YYYY/MM/DD",
};

const _getDateFormat = () => {
	const date = new Date();
	const formattedParts = new Intl.DateTimeFormat(navigator.language)
		.formatToParts(date)
		.map((part) => part.type)
		.filter((type) => ["day", "month", "year"].includes(type));

	return formattedParts.join("-"); // e.g., 'day-month-year' or 'month-day-year'
};

export const getDateFormat = () => {
	return formatMap[_getDateFormat()] || "DD/MM/YYYY";
};

export const getDateTimeFormat = () => {
	return `${formatMap[_getDateFormat()]} HH:mm` || "DD/MM/YYYY HH:mm";
};
