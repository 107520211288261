{
	"populer": "Popüler",
	"buy": "Add to Cart",
	"detail": "Details",
	"no-stock": "Sold Out",
	"optional": "Daily",
	"close": "Close",
	"speaker": "Speakers",
	"modalSpeakerTitle": "Speaker",
	"program": "Program",
	"speakerDetail": "Speaker Details",
	"showAll": "Show All",
	"selectedSession": "Selected Sessions",
	"searchSpeakersSession": "Search for speakers in sessions...",
	"searchSpeaker": "Speaker Search...",
	"notFoundSpeaker": "No speaker was found for your query!",
	"cart": "Cart",
	"price": "Price",
	"quantity": "Quantity",
	"total": "Total: ",
	"goToCart": "Go to Cart",
	"emptyCart": "Cart is empty",
	"free": "Free",
	"modalSessionTitle": "Session",
	"programNotFound": "No program was added or the event is not published yet!",
	"faq": "FAQ",
	"my-ticket": "My Tickets"
}
