import React, { useEffect, useState } from "react";
import axios from "axios";
import { MutatingDots } from "react-loader-spinner";
import Box from "@mui/material/Box";
import { Button, Link, Typography } from "@mui/material";
import Package from "./component/Package";
import Cart from "./component/Cart";
import getLogoUrl from "./utils/get-image-url";
import { formattedTranslationValue } from "./utils/formatted-translation-value";
import PackageHorizontal from "./component/PackageHorizontal";
import { calculateIsDarkColor } from "./utils/color";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { FormattedMessage } from "react-intl";

const RegisterWidget = ({
	language,
	eventID,
	organiserID,
	color,
	displayTitle,
	formID,
	grid,
	view
}) => {
	const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
	const REACT_APP_ENV = process.env.REACT_APP_ENV;
	const REACT_APP_API_URL_TEST = process.env.REACT_APP_API_URL_TEST;
	const REACT_APP_WEB_URL = process.env.REACT_APP_WEB_URL;

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [event, setEvent] = useState([]);
	const [pkgs, setPkgs] = useState([]);
	const [cart, setCart] = useState([]);
	const [cartOpen, setCartOpen] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const url =
					REACT_APP_ENV === "prod" ? REACT_APP_API_URL : REACT_APP_API_URL_TEST;
				const res = await axios({
					method: "get",
					url: `${url}/api/register-form/public/${formID}?basic=false`,
					headers: {
						"Content-Type": "application/json",
						eventID: eventID,
						organiserID: organiserID,
					},
					data: {},
				});
				if (res.status === 200) {
					setEvent(res.data || []);
					setPkgs(res.data.packages || []);
				} else {
					setError(true);
					setLoading(true);
				}
			} catch (error) {
				setError(true);
			} finally {
				setError(false);
				setLoading(false);
			}
		};
		fetchData();
	}, [
		formID,
		organiserID,
		eventID,
		REACT_APP_ENV,
		REACT_APP_API_URL_TEST,
		REACT_APP_API_URL,
	]);

	const pkgsWParent = pkgs.filter((p) => p.parent);
	const parentPkgs = pkgs.filter((p) => !p.parent);
	const tickets = parentPkgs.map((p) => ({
		...p,
		children: pkgsWParent.filter((p_) => p_.parent.id === p.id),
	}));

	const handleCartUpdate = (updatedItem) => {
		setCart((prevCart) => {
			const existingItemIndex = prevCart.findIndex(
				(item) => item.id === updatedItem.id
			);

			if (existingItemIndex !== -1) {
				const updatedCart = [...prevCart];
				updatedCart[existingItemIndex] = {
					...updatedCart[existingItemIndex],
					quantity: updatedCart[existingItemIndex].quantity + 1,
				};
				return updatedCart;
			} else {
				return [...prevCart, { ...updatedItem, quantity: 1 }];
			}
		});
	};

	const removeFromCart = (id) => {
		setCart((prevCart) => prevCart.filter((item) => item.id !== id));
	};

	if (loading || error || event.length === 0) {
		return (
			<Box className="widget-spinner">
				<MutatingDots
					visible={true}
					height="100"
					width="100"
					color={color ?? "#4fa94d"}
					secondaryColor={color ?? "#4fa94d"}
					radius="12.5"
					ariaLabel="mutating-dots-loading"
					wrapperStyle={{}}
					wrapperClass=""
				/>
			</Box>
		);
	}
	return (
		<div
			className="widget-block"
			style={{
				"--color": color,
			}}
		>
			<Box className="widget-block--container">
				<Box
					sx={{
						display: 'grid',
						gridTemplateColumns: {
							xs: 'repeat(2, 1fr)', // mobilde 2 sütun
							sm: 'repeat(6, 1fr)', // küçük ekran üstü için 6 sütun
						},
						alignItems: 'center',
						rowGap: { xs: 2, sm: 0 }, // mobilde dikey aralık
						textAlign: 'center',
					}}
				>
					{/* Biletlerim Butonu */}
					<Box sx={{ gridColumn: { xs: 'span 1', sm: 'span 1' }, display: "flex", justifyContent: 'start', alignItems: 'center' }}>
						<Button
							href={`${REACT_APP_WEB_URL}/participant/login`}
							variant="contained"
							target="_blank"
							endIcon={<ConfirmationNumberIcon />}
							sx={{
								backgroundColor: color,
								color: calculateIsDarkColor(color) ? "#fff" : "#000",
								"&:hover": {
									opacity: 0.8,
									backgroundColor: calculateIsDarkColor(color) ? "#fff" : "#000",
									color: !calculateIsDarkColor(color) ? "#fff" : "#000",
								},
								fontSize: "16px",
							}}
							className="no-toggle"
						>
							<FormattedMessage id="my-ticket" />
						</Button>
					</Box>

					{/* Sepet İkonu */}
					<Box
						className="widget-block--form--cart--icon"
						sx={{
							justifySelf: 'end',
							gridColumn: { xs: 'span 1', sm: 'span 1' },
						}}
					>
						<Cart
							cartItems={cart}
							setCart={setCart}
							removeFromCart={removeFromCart}
							currency={event?.currency}
							formID={formID}
							cartOpen={cartOpen}
							setCartOpen={setCartOpen}
							language={language}
							color={color}
							free={event?.participationType === "free"}
						/>
					</Box>

					{/* Başlık */}
					{displayTitle === "true" && (
						<Box
							sx={{
								gridColumn: { xs: '1 / -1', sm: '2 / span 4' }, // mobilde tüm genişliği kapsasın
							}}
						>
							<Typography
								variant="h2"
								component="h2"
								className="widget-block--shoppingTitle"
								sx={{
									textAlign: "center",
								}}
							>
								{formattedTranslationValue(event?.translations, "name", language)}
							</Typography>
						</Box>
					)}
				</Box>

				<Box className="widget-block--form">
					{event?.headerPhoto && (
						<Box className="widget-block--form--headerImgaeContainer">
							<img
								className="widget-block--form--headerImgaeContainer--image"
								src={getLogoUrl(event?.headerPhoto)}
								alt={event?.name}
							/>
						</Box>
					)}
					{formattedTranslationValue(
						event?.translations,
						"description",
						language
					) && (
							<Box
								className="widget-block--form--headerDescriptionContainer"
								dangerouslySetInnerHTML={{
									__html: formattedTranslationValue(
										event?.translations,
										"description",
										language
									),
								}}
							></Box>
						)}
					{view === 'horizontal' ?
						<Box
							className="widget-block--form--cart--container--horizontal"
							sx={{
								display: "grid",

							}}
						>
							{
								tickets &&
								tickets.map((pkg, key) => (
									<PackageHorizontal
										pkg={pkg}
										currency={event?.currency}
										event={event}
										key={key}
										color={color}
										onCartUpdate={handleCartUpdate}
										cart={cart}
										setCartOpen={setCartOpen}
										free={event?.participationType === "free"}
										language={language}
									/>
								))
							}
						</Box>

						:
						<Box
							className="widget-block--form--cart--container"
							sx={{
								display: "grid",
								gap: 4,
								gridTemplateColumns: {
									xs: "repeat(1, minmax(0, 1fr))",
									md: `repeat(${grid}, minmax(0, 1fr))`,
									lg: `repeat(${grid}, minmax(0, 1fr))`,
								},
							}}
						>
							{
								tickets &&
								tickets.map((pkg, key) => (
									<Package
										pkg={pkg}
										currency={event?.currency}
										event={event}
										key={key}
										color={color}
										onCartUpdate={handleCartUpdate}
										cart={cart}
										setCartOpen={setCartOpen}
										free={event?.participationType === "free"}
										language={language}
									/>
								))
							}
						</Box>}

					{event?.footerPhoto && (
						<Box className="widget-block--form--footerImgaeContainer">
							<img
								className="widget-block--form--headerImgaeContainer--image"
								src={getLogoUrl(event?.footerPhoto)}
								alt={event?.name}
							/>
						</Box>
					)}
				</Box>
			</Box>
		</div>
	);
};

export default RegisterWidget;
