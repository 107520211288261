export const cleanEmptyHTMLTags = (html) => {
	if (!html) return html; // Eğer içerik yoksa, direkt döndür

	// Boş HTML etiketlerini temizle
	const cleanedHTML = html
		.replace(/<p><br><\/p>/gi, "")
		.replace(/<p><\/p>/gi, "");

	// Eğer temizlenmiş içerik boşsa, null veya boş string döndür
	return cleanedHTML.trim() === "" ? null : cleanedHTML;
};
