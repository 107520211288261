const ColorTypeEnum = {
  ROOT: 0,
  RGB: 1,
  HEX: 2,
};

const rgbToValues = (rgbCode) => {
  const match = rgbCode.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
  if (match) {
    return match.slice(1, 4).map(Number);
  }
  return null;
};

const calculateColorType = (code) => {
  if (code.includes('--color')) return ColorTypeEnum.ROOT;
  if (code.includes('rgb')) return ColorTypeEnum.RGB;
  if (code.includes('#')) return ColorTypeEnum.HEX;
  return ColorTypeEnum.ROOT;
};

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b, array: [r, g, b] };
};

const addAlphaToColor = (hexColor, alpha) => {
  const { r, g, b } = hexToRgb(hexColor);
  return `rgb(${Math.round((1 - alpha) * 255 + alpha * r)}, ${Math.round(
    (1 - alpha) * 255 + alpha * g,
  )}, ${Math.round((1 - alpha) * 255 + alpha * b)})`;
};

const calculateBrightness = (rgb) => {
  const [red, green, blue] = rgb.map((value) => value / 255);
  return 0.299 * red + 0.587 * green + 0.114 * blue < 0.6;
};

const calculateIsDarkColor = (code) => {
  let rgb;
  switch (calculateColorType(code)) {
    case ColorTypeEnum.ROOT:
      if (typeof window === 'undefined' || typeof document === 'undefined') {
        return null;
      }
      const color = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(code);
      rgb = rgbToValues(color);
      break;
    case ColorTypeEnum.HEX:
      rgb = hexToRgb(code).array;
      break;
    case ColorTypeEnum.RGB:
      rgb = rgbToValues(code);
      break;
    default:
      rgb = null;
  }
  return rgb ? calculateBrightness(rgb) : null;
};

export {
  ColorTypeEnum,
  rgbToValues,
  calculateColorType,
  hexToRgb,
  addAlphaToColor,
  calculateIsDarkColor,
};
