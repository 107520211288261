import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import SponsorsWidget from "./SponsorsWidget";
import SpeakersWidget from "./SpeakersWidget";
import ProgramWidget from "./ProgramWidget";
import RegisterWidget from "./RegisterWidget";
import enMessages from "./locales/en.json";
import trMessages from "./locales/tr.json";
import { Provider } from "react-redux";
import { store } from "./store";

import "./widget.scss";
import FaqWidget from "./FaqWidget";

const messages = {
	en: enMessages,
	tr: trMessages,
};

class KapitalEventsWidgetElement extends HTMLElement {
	connectedCallback() {
		const resource = this.getAttribute("resource");
		const language = this.getAttribute("language");
		const eventID = this.getAttribute("eventID");
		const organiserID = this.getAttribute("organiserID");
		const color = this.getAttribute("color") || "#fff";
		const displayTitle = this.getAttribute("displayTitle") || "true";
		const formID = this.getAttribute("formID") || "";
		const grid = this.getAttribute("grid") || 3;
		const view = this.getAttribute("view") || "box";
		const topics = this.getAttribute("topics") || "";

		const root = ReactDOM.createRoot(this);
		root.render(
			<Provider store={store}>
				<IntlProvider locale={language} messages={messages[language]}>
					{resource === "register" && (
						<RegisterWidget
							language={language}
							eventID={eventID}
							organiserID={organiserID}
							color={color}
							displayTitle={displayTitle}
							formID={formID}
							grid={grid}
							view={view}
						/>
					)}
					{resource === "program" && (
						<ProgramWidget
							language={language}
							eventID={eventID}
							organiserID={organiserID}
							color={color}
							displayTitle={displayTitle}
						/>
					)}
					{resource === "speakers" && (
						<SpeakersWidget
							language={language}
							eventID={eventID}
							organiserID={organiserID}
							color={color}
							displayTitle={displayTitle}
							topics={topics}
						/>
					)}
					{resource === "sponsors" && (
						<SponsorsWidget
							language={language}
							eventID={eventID}
							organiserID={organiserID}
							color={color}
							displayTitle={displayTitle}
						/>
					)}
					{resource === "faq" && (
						<FaqWidget
							language={language}
							eventID={eventID}
							organiserID={organiserID}
							color={color}
							displayTitle={displayTitle}
						/>
					)}
				</IntlProvider>
			</Provider>
		);
	}
}

if (!customElements.get("kapital-events-widget"))
	customElements.define("kapital-events-widget", KapitalEventsWidgetElement);
