import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import {
	Box,
	Typography,
	FormControlLabel,
	InputBase,
	Paper,
	IconButton,
	Switch,
	Divider,
	Modal,
	Stack,
	Link,
	Alert,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { MutatingDots } from "react-loader-spinner";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RoomIcon from "@mui/icons-material/Room";
import CoffeeIcon from "@mui/icons-material/Coffee";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MuiTooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { useIntl } from "react-intl";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkIcon from "@mui/icons-material/Link";
import "swiper/swiper-bundle.css";
import { FormattedMessage } from "react-intl";
import { cleanEmptyHTMLTags } from "./hooks/cleanEmptyHTMLTags";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useDispatch, useSelector } from "react-redux";
import {
	emptySpeaker,
	getPrograms,
	getSpeakersDetail,
} from "./store/slices/program";

const BootstrapTooltip = styled(({ className, ...other }) => (
	<MuiTooltip {...other} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#000",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#000",
	},
}));

const ProgramWidget = ({
	language,
	eventID,
	organiserID,
	color,
	displayTitle,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [searchQuery, setSearchQuery] = useState("");
	const [activeDay, setActiveDay] = useState(0);
	const [showAll, setShowAll] = useState(true);
	const [selectedSalons, setSelectedSalons] = useState({});
	const [selectedSession, setSelectedSession] = useState({});
	const swiperRef = useRef(null);

	const [modalOpen, setModalOpen] = useState(false);
	const [sessionModalOpen, setSessionModalOpen] = useState(false);

	const { error, loading, program } = useSelector((state) => state.program);
	const speakerDetails = useSelector((state) => state.program.speaker);

	useEffect(() => {
		// dispatch(getEvent({ eventID }))
		dispatch(
			getPrograms({
				organiserID: organiserID,
				eventID: eventID,
				language: language,
			})
		);
	}, [eventID, organiserID, language, dispatch]);

	useEffect(() => {
		if (!Array.isArray(program) || program.length === 0) {
			return; // Eğer program boş veya null/undefined ise kodu çalıştırma
		}

		const initialDayData = program[0];
		if (!initialDayData || !Array.isArray(initialDayData.halls)) {
			return; // Eğer initialDayData veya halls tanımlı değilse işlemi durdur
		}

		const allSelected = {};
		initialDayData.halls.forEach((salon) => {
			allSelected[salon.id] = true;
		});

		setSelectedSalons(allSelected);
	}, [program]);

	if (loading || error) {
		return (
			<Box className="widget-spinner">
				<MutatingDots
					visible={true}
					height="100"
					width="100"
					color="#4fa94d"
					secondaryColor="#4fa94d"
					radius="12.5"
					ariaLabel="mutating-dots-loading"
					wrapperStyle={{}}
					wrapperClass=""
				/>
			</Box>
		);
	}

	const handleDayChange = (newValue) => {
		if (!Array.isArray(program) || program.length === 0) {
			return; // Eğer program boş veya undefined ise işlemi yapma
		}

		const dayData = program[newValue];
		if (!dayData) return;

		setActiveDay(newValue);

		const allSelected = {};
		dayData.halls.forEach((salon) => {
			allSelected[salon.id] = true;
		});

		setSelectedSalons(allSelected);
		setShowAll(true);
	};

	const handleSalonToggle = (salonId) => {
		const dayData = program[activeDay];
		setSelectedSalons((prev) => {
			const updatedSalons = { ...prev, [salonId]: !prev[salonId] };
			const allSelected = dayData.halls.every(
				(salon) => updatedSalons[salon.id]
			);
			setShowAll(allSelected);
			return updatedSalons;
		});
	};

	const handleShowAllToggle = () => {
		const dayData = program[activeDay];
		const newShowAll = !showAll;

		setShowAll(newShowAll);

		if (newShowAll) {
			const allSelected = {};
			dayData.halls.forEach((salon) => {
				allSelected[salon.id] = true;
			});
			setSelectedSalons(allSelected);
		} else {
			setSelectedSalons({});
		}
	};

	const filteredOturumlar = () => {
		if (!Array.isArray(program) || program.length === 0) {
			return []; // Eğer program yüklenmediyse boş dizi döndür
		}

		const dayData = program[activeDay] || null;
		if (!dayData) return [];
		// if (showAll) {

		// 	return dayData.halls.flatMap((salon) => salon.programs);
		// }

		if (showAll) {
			return dayData.halls
				.flatMap((salon) => salon.programs)
				.sort((a, b) => {
					const toMinutes = (time) => {
						const [hour, minute] = time.split(":").map(Number);
						return hour * 60 + minute;
					};
					return toMinutes(a.startTime) - toMinutes(b.startTime);
				});
		}

		return dayData.halls
			.filter((salon) => selectedSalons[salon.id])
			.flatMap((salon) => salon.programs);
	};

	const searchedOturumlar = filteredOturumlar().filter((oturum) => {
		const lowerCaseQuery = searchQuery.toLowerCase();

		// Oturum adı içinde arama
		const isMatchInSessionName = oturum.title
			.toLowerCase()
			.includes(lowerCaseQuery);

		// Konuşmacıların isimleri içinde arama
		const isMatchInSpeakers = oturum.speakers?.some((speaker) =>
			speaker.name.toLowerCase().includes(lowerCaseQuery)
		);

		// Eğer oturum adı ya da konuşmacı adı eşleşiyorsa, bu oturumu döndür
		return isMatchInSessionName || isMatchInSpeakers;
	});
	const handlePreviousClick = () => {
		swiperRef.current?.slidePrev(); // Use Swiper instance to go to the previous slide
	};

	const handleNextClick = () => {
		swiperRef.current?.slideNext(); // Use Swiper instance to go to the next slide
	};

	// Modal
	const handleSpeakerClick = (speaker) => {
		setSessionModalOpen(false); // Oturum modal'ını kapat
		setModalOpen(true); // Konuşmacı modal'ını aç

		dispatch(
			getSpeakersDetail({
				id: speaker.id,
				organiserID: organiserID,
				eventID: eventID,
				language: language,
			})
		);
	};

	const handleCloseModal = () => {
		setModalOpen(false);
		dispatch(emptySpeaker());
		//burası
	};

	const handleSessionClick = (session) => {
		if (!Array.isArray(program) || program.length === 0) {
			return;
		}

		const matchedProgram =
			program.find((item) => item.id === session.programDayId) || null;
		const matchedHall =
			matchedProgram?.halls?.find(
				(hall) => hall.id === session.programHallId
			) || null;
		setSelectedSession({
			...session,
			matchedProgram, // Eşleşen program bilgisi
			matchedHall, // Eşleşen salon bilgisi (hall)
		});
		setSessionModalOpen(true);
	};

	const handleSessionCloseModal = () => {
		setSessionModalOpen(false);
		setSelectedSession(null);
	};

	if (loading) {
		return (
			<Box className="widget-spinner">
				<MutatingDots
					visible={true}
					height="100"
					width="100"
					color="#4fa94d"
					secondaryColor="#4fa94d"
					radius="12.5"
					ariaLabel="mutating-dots-loading"
				/>
			</Box>
		);
	}

	return (
		<>
			{!Array.isArray(program) || program.length === 0 ? (
				<Box className="widget-block--search-not">
					<Alert severity="info">
						<FormattedMessage id="programNotFound" />
					</Alert>
				</Box>
			) : (
				<div className="widget-block" style={{ "--color": color }}>
					{displayTitle === "true" && (
						<Box>
							<Typography
								variant="h2"
								component="h2"
								className="widget-block--header-title"
							>
								<FormattedMessage id="program" />
							</Typography>
						</Box>
					)}
					<Box className="widget-block--program">
						{/* Günler için Swiper */}
						<Box sx={{ position: "relative" }}>
							<IconButton
								className="widget-block--program--navigation--left"
								onClick={handlePreviousClick}
								aria-label="previous"
							>
								<KeyboardArrowLeftIcon />
							</IconButton>
							<Swiper
								spaceBetween={10}
								slidesPerView={4}
								onSlideChange={(swiper) => handleDayChange(swiper.activeIndex)}
								initialSlide={activeDay}
								modules={[Navigation]}
								onSwiper={(swiper) => (swiperRef.current = swiper)} // Attach the swiper instance to ref
								navigation={false} // Disable default navigation
								breakpoints={{
									320: {
										slidesPerView: 1,
										spaceBetween: 10,
									},
									420: {
										slidesPerView: 2,
										spaceBetween: 10,
									},
									768: {
										slidesPerView: 2,
										spaceBetween: 10,
									},
									1024: {
										slidesPerView: 4,
										spaceBetween: 10,
									},
								}}
							>
								{program.length > 1 &&
									program.map((day, index) => (
										<SwiperSlide
											key={day.id}
											className={
												index === activeDay
													? "widget-block--program--days active-tab"
													: "widget-block--program--days"
											}
										>
											<Box
												className="widget-block--program--days--item"
												onClick={() => handleDayChange(index)}
												sx={{
													backgroundColor:
														index === activeDay
															? program[activeDay]?.color
															: "#f7f7f8",
													color: index === activeDay ? "#fff" : "#36383d",
													border: `1px ${
														index === activeDay ? "solid" : "dashed"
													} ${index === activeDay ? "#f00" : "#ccc"}`,
												}}
											>
												<Typography
													variant="span"
													className="widget-block--program--days--item--date"
												>
													{day.date}
												</Typography>
												<Typography
													variant="span"
													className="widget-block--program--days--item--name"
												>
													{day.name}
												</Typography>
											</Box>
										</SwiperSlide>
									))}
							</Swiper>
							<IconButton
								className="widget-block--program--navigation--right"
								onClick={handleNextClick}
								aria-label="next"
							>
								<ChevronRightIcon />
							</IconButton>
						</Box>
						<Box className="widget-block--program--saloons">
							<FormControlLabel
								control={
									<Switch
										checked={showAll}
										onChange={handleShowAllToggle}
										sx={{
											"& .MuiSwitch-switchBase.Mui-checked": {
												color: color, // Açık durum rengi
											},
											"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
												{
													backgroundColor: "#000", // Açık durum iz rengi
												},
										}}
									/>
								}
								label={
									<Typography
										variant="span"
										className="widget-block--program--saloons--title"
									>
										<FormattedMessage id="showAll" />
									</Typography>
								}
							/>
							{program[activeDay]?.halls.map((salon) => (
								<FormControlLabel
									key={salon.id}
									control={
										<Switch
											checked={!!selectedSalons[salon.id]}
											onChange={() => handleSalonToggle(salon.id)}
											color="default" // Farklı bir renk ayarı (isteğe bağlı)
											sx={{
												"& .MuiSwitch-switchBase.Mui-checked": {
													color: color, // Açık durum rengi
												},
												"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
													{
														backgroundColor: "#000", // Açık durum iz rengi
													},
											}}
										/>
									}
									label={
										<Typography
											variant="span"
											className="widget-block--program--saloons--title"
										>
											{salon.name}
										</Typography>
									}
								/>
							))}
						</Box>

						<Box className="widget-block--program--session">
							<Divider />
							<Typography
								variant="h4"
								component="h4"
								className="widget-block--program--session--header-title"
								sx={{
									position: "relative",
									"&::after": {
										backgroundColor: program[activeDay]?.color,
									},
								}}
							>
								{showAll ? (
									`${program[activeDay]?.date}`
								) : (
									<FormattedMessage id="selectedSession" />
								)}
							</Typography>
							<Box className="widget-block--program--search-container">
								<Paper
									component="form"
									sx={{
										display: "flex",
										alignItems: "center",
										width: "100%",
										borderRadius: "0.8em",
									}}
								>
									<InputBase
										sx={{
											ml: 1,
											flex: 1,
											borderRadius: "0.8em",
											fontSize: "0.875em",
											paddingLeft: "0.5em",
										}}
										placeholder={intl.formatMessage({
											id: "searchSpeakersSession",
										})}
										onChange={(e) => setSearchQuery(e.target.value)}
										value={searchQuery}
									/>
									{searchQuery ? (
										<IconButton
											type="button"
											sx={{ p: "10px" }}
											aria-label="search"
											onClick={() => setSearchQuery("")}
										>
											<HighlightOffIcon />
										</IconButton>
									) : (
										<IconButton
											type="button"
											sx={{ p: "10px" }}
											aria-label="search"
										>
											<SearchIcon />
										</IconButton>
									)}
								</Paper>
							</Box>
							<Box className="widget-block--program--session--container">
								{searchedOturumlar.map((oturum, index) => (
									<Box
										className={
											oturum?.type === "break"
												? `widget-block--program--session--items widget-block--program--session--items--break`
												: `widget-block--program--session--items`
										}
										key={index}
										onClick={
											oturum?.type !== "section"
												? () => handleSessionClick(oturum)
												: undefined
										}
										sx={{
											borderLeft: "0.25em solid",
											borderColor: program[activeDay]?.color ?? color,
											backgroundColor:
												oturum?.type === "section"
													? program[activeDay]?.color ?? color
													: "inherit",
											color: oturum?.type === "section" ? "#ffffff" : "inherit",
										}}
									>
										{oturum?.type === "break" && (
											<Box className="widget-block--program--session--items--break--container">
												<CoffeeIcon
													style={{
														color: program[activeDay]?.color ?? color,
														fontSize: "2.4em",
													}}
												/>
											</Box>
										)}

										<Box className="widget-block--program--session--items--item">
											{oturum?.type !== "section" && (
												<Box className="widget-block--program--session--items--item--header">
													<Box className="widget-block--program--session--items--item--header--left">
														<Box className="widget-block--program--session--items--item--header--left--box">
															<RoomIcon
																style={{ color: "#8b8b8b", fontSize: "1em" }}
															/>
															<Typography variant="span" className="">
																{
																	program[activeDay].halls.find((salon) =>
																		salon.programs.some(
																			(oturumItem) =>
																				oturumItem.id === oturum.id
																		)
																	)?.name
																}
															</Typography>
														</Box>
														<Box className="widget-block--program--session--items--item--header--left--box">
															<AccessTimeIcon
																style={{ color: "#8b8b8b", fontSize: "1rem" }}
															/>
															<Typography variant="span">
																{oturum?.startTime} - {oturum?.endTime}
															</Typography>
														</Box>
													</Box>
													<Box className="widget-block--program--session--items--item--header--right">
														<Box className="widget-block--program--session--items--item--header--left--box">
															<CalendarMonthIcon
																style={{ color: "#8b8b8b", fontSize: "1rem" }}
															/>
															<Typography variant="span">
																{program[activeDay]?.date}
															</Typography>
														</Box>
													</Box>
												</Box>
											)}
											<Box className="widget-block--program--session--items--item--container">
												<Box className="widget-block--program--session--items--item--container--title">
													{oturum?.title}
												</Box>
												<Box className="widget-block--program--session--items--item--container--speakers">
													{oturum?.speakers && (
														<AvatarGroup sx={{ justifyContent: "start" }}>
															{oturum.speakers.map((item, index) => (
																<BootstrapTooltip
																	onClick={(e) => {
																		e.stopPropagation(); // Olayın yayılmasını durdur
																		handleSpeakerClick(item);
																	}}
																	title={
																		<Box className="widget-block--program--session--items--item--container--speakers--tooltip">
																			<Typography
																				variant="span"
																				className="widget-block--program--session--items--item--container--speakers--name"
																			>
																				{item?.name}
																			</Typography>
																			<Typography
																				variant="span"
																				className="widget-block--program--session--items--item--container--speakers--title"
																			>
																				{item?.title}
																			</Typography>
																		</Box>
																	}
																	key={index}
																>
																	<Avatar
																		alt={item.name}
																		src={item?.speakerImagePath}
																		sx={{ cursor: "pointer" }}
																	/>
																</BootstrapTooltip>
															))}
														</AvatarGroup>
													)}
												</Box>
											</Box>
										</Box>
									</Box>
								))}
							</Box>
						</Box>
					</Box>
					{modalOpen && (
						<Modal
							open={modalOpen}
							aria-labelledby="modal-title"
							onClose={handleCloseModal}
						>
							<Box className="widget-block--program--modal">
								<Box sx={{ textAlign: "center" }}>
									<IconButton
										onClick={handleCloseModal}
										sx={{ position: "absolute", top: 8, right: 8 }}
									>
										<CloseIcon />
									</IconButton>
									<Typography
										variant="h6"
										sx={{ fontWeight: "bold", pb: "1em" }}
									>
										<FormattedMessage id="modalSpeakerTitle" />
									</Typography>
								</Box>
								<Divider />
								<Box className="widget-block--program--modal--speaker--container">
									{speakerDetails?.sponsor?.isSupport && (
										<Box className="widget-block--program--modal--speaker--container--sponsor">
											{speakerDetails?.sponsor?.link ? (
												<Link
													href={speakerDetails?.sponsor?.link}
													target="_blank"
													rel="noopener noreferrer"
													sx={{ display: "inline-block" }}
												>
													<Avatar
														alt={speakerDetails?.name}
														src={speakerDetails?.sponsor.logo}
														sx={{
															borderRadius: "16px",
															width: { xs: 72, sm: 100, md: 100 },
															height: { xs: 72, sm: 100, md: 100 },
														}}
													/>
												</Link>
											) : (
												<Avatar
													alt={speakerDetails?.name}
													src={speakerDetails?.sponsor.logo}
													sx={{
														borderRadius: "16px",
														width: { xs: 72, sm: 100, md: 100 },
														height: { xs: 72, sm: 100, md: 100 },
													}}
												/>
											)}
										</Box>
									)}
									<Box className="widget-block--program--modal--speaker--header">
										<Avatar
											alt="Remy Sharp"
											src={speakerDetails?.speakerImagePath}
											className="widget-block--program--modal--speaker--header--avatar"
										/>
										<Box>
											<Typography
												variant="h5"
												className="widget-block--program--modal--speaker--header--name"
											>
												{speakerDetails?.name}
											</Typography>
											<Typography
												variant="body2"
												color="textSecondary"
												className="widget-block--program--modal--speaker--header--title"
											>
												{speakerDetails?.title}
											</Typography>
										</Box>
										<Box className="widget-block--program--modal--speaker--header--social-media">
											{speakerDetails?.socialMedia?.facebook && (
												<IconButton
													component="a"
													href={speakerDetails?.socialMedia?.facebook}
													target="_blank"
													rel="noopener noreferrer"
													aria-label="Facebook"
												>
													<FacebookIcon />
												</IconButton>
											)}
											{speakerDetails?.socialMedia?.twitter && (
												<IconButton
													component="a"
													href={speakerDetails?.socialMedia?.twitter}
													target="_blank"
													rel="noopener noreferrer"
													aria-label="X (formerly Twitter)"
												>
													<XIcon />
												</IconButton>
											)}
											{speakerDetails?.socialMedia?.linkend && (
												<IconButton
													component="a"
													href={speakerDetails?.socialMedia?.linkend}
													target="_blank"
													rel="noopener noreferrer"
													aria-label="LinkedIn"
												>
													<LinkedInIcon />
												</IconButton>
											)}
											{speakerDetails?.socialMedia?.youtube && (
												<IconButton
													component="a"
													href={speakerDetails?.socialMedia?.youtube}
													target="_blank"
													rel="noopener noreferrer"
													aria-label="YouTube"
												>
													<YouTubeIcon />
												</IconButton>
											)}
											{speakerDetails?.socialMedia?.instagram && (
												<IconButton
													component="a"
													href={speakerDetails?.socialMedia?.instagram}
													target="_blank"
													rel="noopener noreferrer"
													aria-label="Instagram"
												>
													<InstagramIcon />
												</IconButton>
											)}
											{speakerDetails?.socialMedia?.website && (
												<IconButton
													component="a"
													href={speakerDetails?.socialMedia?.website}
													target="_blank"
													rel="noopener noreferrer"
													aria-label="YouTube"
												>
													<LinkIcon />
												</IconButton>
											)}
										</Box>
									</Box>
									{cleanEmptyHTMLTags(speakerDetails?.biography) && (
										<Box className="widget-block--program--modal--speaker--bio">
											<Box
												sx={{
													marginTop: 2,
													textAlign: "left",
													width: "100%",
													overflowY: "auto",
													fontFamily: "'Lato', sans-serif",
													fontSize: "0.875em",
													color: "#818181",
												}}
												dangerouslySetInnerHTML={{
													__html: cleanEmptyHTMLTags(speakerDetails?.biography),
												}}
											/>
										</Box>
									)}

									{speakerDetails?.programs &&
										speakerDetails?.programs.length > 0 && (
											<Box className="widget-block--program--modal--speaker--session-box">
												{speakerDetails?.programs.map((item, key) => (
													<Box
														className="widget-block--program--modal--speaker--session-box--item"
														key={key}
													>
														<Box className="widget-block--program--modal--speaker--session-box--item--header">
															<Box className="widget-block--program--modal--speaker--session-box--item--header--left">
																<Box className="widget-block--program--modal--speaker--session-box--item--header--left--item">
																	<AccessTimeIcon />
																	<Typography variant="span">
																		{item?.startTime} - {item?.endTime}{" "}
																	</Typography>
																</Box>
																<Box className="widget-block--program--modal--speaker--session-box--item--header--left--item">
																	<RoomIcon />
																	<Typography variant="span">
																		{item?.hall.name}
																	</Typography>
																</Box>
															</Box>
															<Box className="widget-block--program--modal--speaker--session-box--item--header--right">
																<CalendarMonthIcon />
																<Typography variant="span">
																	{item?.day.date}
																</Typography>
															</Box>
														</Box>
														<Box className="widget-block--program--modal--speaker--session-box--item--body">
															<Typography variant="span">
																{item?.title}
															</Typography>
															{item?.speakers && item.speakers.length > 0 && (
																<Box>
																	<AvatarGroup sx={{ justifyContent: "start" }}>
																		asdas
																		{item?.speakers.map(
																			(itemSpeaker, keySpeaker) => (
																				<BootstrapTooltip
																					onClick={(e) => {
																						e.stopPropagation(); // Olayın yayılmasını durdur
																						handleSpeakerClick(itemSpeaker);
																					}}
																					title={
																						<Box className="widget-block--program--session--items--item--container--speakers--tooltip">
																							<Typography
																								variant="span"
																								className="widget-block--program--session--items--item--container--speakers--name"
																							>
																								{itemSpeaker?.name}
																							</Typography>
																							<Typography
																								variant="span"
																								className="widget-block--program--session--items--item--container--speakers--title"
																							>
																								{itemSpeaker?.title}
																							</Typography>
																						</Box>
																					}
																					key="1"
																				>
																					<Avatar
																						alt={itemSpeaker?.name}
																						src={itemSpeaker?.speakerImagePath}
																						sx={{ cursor: "pointer" }}
																					/>
																				</BootstrapTooltip>
																			)
																		)}
																	</AvatarGroup>
																</Box>
															)}
														</Box>
													</Box>
												))}
											</Box>
										)}
								</Box>
							</Box>
						</Modal>
					)}

					{sessionModalOpen && (
						<Modal
							open={sessionModalOpen}
							aria-labelledby="modal-title"
							onClose={handleSessionCloseModal}
						>
							<Box className="widget-block--program--modal">
								<Box sx={{ textAlign: "center" }}>
									<IconButton
										onClick={handleSessionCloseModal}
										sx={{ position: "absolute", top: 8, right: 8 }}
									>
										<CloseIcon />
									</IconButton>
									<Typography
										variant="h6"
										sx={{ fontWeight: "bold", pb: "1em" }}
									>
										<FormattedMessage id="modalSessionTitle" />
									</Typography>
								</Box>
								<Divider />
								<Box className="widget-block--program--modal--session--container">
									<Box className="widget-block--program--modal--session--container--header">
										<Typography
											variant="span"
											className="widget-block--program--modal--session--container--header--title"
										>
											{selectedSession?.title}
										</Typography>
										<Box className="widget-block--program--modal--session--container--header--saloon">
											<RoomIcon
												sx={{
													color: selectedSession.matchedProgram?.color ?? color,
												}}
											/>
											<Typography
												variant="span"
												className="widget-block--program--modal--session--container--header--saloon--title"
											>
												{selectedSession?.matchedHall?.name}
											</Typography>
										</Box>
										<Box className="widget-block--program--modal--session--container--header--date">
											<Box className="widget-block--program--modal--session--container--header--date--item">
												<Box className="widget-block--program--modal--session--container--header--date--item--icon">
													<DateRangeIcon
														sx={{
															color:
																selectedSession.matchedProgram?.color ?? color,
														}}
													/>
												</Box>
												<Typography
													variant="span"
													className="widget-block--program--modal--session--container--header--date--item--text"
												>
													{selectedSession?.matchedProgram?.date}
												</Typography>
											</Box>
											<Box className="widget-block--program--modal--session--container--header--date--item">
												<Box className="widget-block--program--modal--session--container--header--date--item--icon">
													<AccessTimeIcon
														sx={{
															color:
																selectedSession.matchedProgram?.color ?? color,
														}}
													/>
												</Box>
												<Typography
													variant="span"
													className="widget-block--program--modal--session--container--header--date--item--text"
												>
													{selectedSession?.startTime} -{" "}
													{selectedSession?.endTime}
												</Typography>
											</Box>
										</Box>
									</Box>
									{cleanEmptyHTMLTags(selectedSession?.content) && (
										<Box className="widget-block--program--modal--session--container--content">
											<Box
												dangerouslySetInnerHTML={{
													__html: cleanEmptyHTMLTags(selectedSession?.content),
												}}
											/>
										</Box>
									)}

									{selectedSession?.speakers.length > 0 && (
										<Box className="widget-block--program--modal--session--container--speakers">
											{selectedSession?.speakers.map((row, index) => (
												<Box
													className="widget-block--program--modal--session--container--speakers--item"
													key={index}
												>
													<Stack
														direction="row"
														alignItems="center"
														spacing={2}
														onClick={(e) => {
															e.stopPropagation(); // Olayın yayılmasını durdur
															handleSpeakerClick(row);
														}}
													>
														<Avatar
															alt={row?.name}
															src={row?.speakerImagePath}
														/>
														<Stack>
															<Stack
																direction="row"
																alignItems="center"
																spacing={0.25}
															>
																<Typography
																	variant="subtitle1"
																	className="widget-block--program--modal--session--container--speakers--item--name"
																>
																	{row?.name}
																</Typography>
															</Stack>
															<Typography
																variant="subtitle2"
																noWrap
																className="widget-block--program--modal--session--container--speakers--item--title"
															>
																{row?.title}
															</Typography>
														</Stack>
													</Stack>
												</Box>
											))}
										</Box>
									)}
								</Box>
							</Box>
						</Modal>
					)}
				</div>
			)}
		</>
	);
};

export default ProgramWidget;
