import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { MutatingDots } from "react-loader-spinner";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Link } from "@mui/material";

const SponsorsWidget = ({ language, eventID, organiserID, color, displayTitle }) => {
	const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
	const REACT_APP_ENV = process.env.REACT_APP_ENV;
	const REACT_APP_API_URL_TEST = process.env.REACT_APP_API_URL_TEST;
	const [sponsors, setSponsors] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		const url =
			REACT_APP_ENV === "prod" ? REACT_APP_API_URL : REACT_APP_API_URL_TEST;
		axios({
			method: "get",
			url: `${url}/api/sponsor-category/public/${organiserID}/${eventID}/${language}`,
			headers: {
				"Content-Type": "application/json",
			},
			data: {},
		})
			.then((res) => {
				setSponsors(res.data.sponsors || []);
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [
		eventID,
		organiserID,
		REACT_APP_ENV,
		REACT_APP_API_URL,
		REACT_APP_API_URL_TEST,
		language,
	]);

	if (loading || error) {
		return (
			<Box className="widget-spinner">
				<MutatingDots
					visible={true}
					height="100"
					width="100"
					color="#4fa94d"
					secondaryColor="#4fa94d"
					radius="12.5"
					ariaLabel="mutating-dots-loading"
					wrapperStyle={{}}
					wrapperClass=""
				/>
			</Box>
		);
	}

	return (
		<div
			className="widget-block"
			style={{
				"--color": color,
			}}
		>
			{displayTitle === "true" && (
				<Box>
					<Typography
						variant="h2"
						component="h2"
						className="widget-block--header-title"
					>
						{language === "en" ? "Sponsors" : "Sponsorlar"}
					</Typography>
				</Box>
			)}
			{sponsors && (
				<Box className="widget-block--sponsors">
					<Grid container spacing={2}>
						{sponsors?.map((item, key) => {
							// Determine the column size based on the item.size value
							const columnSize =
								item?.size === "sm"
									? 3
									: item?.size === "md"
										? 6
										: item?.size === "lg"
											? 9
											: item?.size === "xl"
												? 12
												: 12;
							return (
								<Grid item xs={12} sm={12} md={columnSize} key={key}>
									<Box className="widget-block--sponsors--cat">
										<Box className="widget-block--sponsors--cat--title">
											<Typography variant="h4">{item.category}</Typography>
										</Box>
										<Box className="widget-block--sponsors--cat--logos-container">
											{item.logos.map((logo, logKey) => (
												<Box
													key={logKey}
													className="widget-block--sponsors--cat--logo"
												>
													{logo.link ? (
														<Link href={logo?.link} target="_blank" alt="">
															<img
																src={logo.imgPath}
																width="150"
																height="150"
																alt={`Sponsor logo ${logKey + 1}`}
															/>
														</Link>
													) : (
														<img
															src={logo?.imgPath}
															width="150"
															height="150"
															alt={`Sponsor logo ${logKey + 1}`}
														/>
													)}
												</Box>
											))}
										</Box>
									</Box>
								</Grid>
							);
						})}
					</Grid>
				</Box>
			)}
		</div>
	);
};

export default SponsorsWidget;
