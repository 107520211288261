import React from 'react'
import List from "@mui/material/List"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import formatCurrency from "../utils/currencyFormat"
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { FormattedMessage } from "react-intl"
import { formattedTranslationValue } from "../utils/formatted-translation-value"

const CardList = ({ formCurrency, data, removeFromCart, incrementHandler, decrementHandler, language }) => {


    const Increment = ({ period }) => {
        return (
            <ButtonGroup
                size="large"
                variant="text"
                color="inherit"
                sx={{ border: "1px solid", borderColor: "divider" }}
            >
                <Button
                    key="three"
                    disabled={period.quantity <= 1}
                    onClick={() => decrementHandler(period.id)}
                    sx={{ pr: 0.75, pl: 0.75, minWidth: "0px !important" }}
                    aria-label="'decrease'"
                >
                    <RemoveIcon fontSize="inherit" />
                </Button>
                <Button key="two" sx={{ pl: 0.5, pr: 0.5 }}>
                    {period.quantity}
                </Button>
                <Button
                    key="one"
                    onClick={() => incrementHandler(period.id)}
                    sx={{ pl: 0.75, pr: 0.75, minWidth: "0px !important" }}
                    aria-label="'increase'"
                    disabled={period.quantity === period.stock ? true : false}
                >
                    <AddIcon fontSize="inherit" />
                </Button>
            </ButtonGroup>
        );
    };

    return (
        <>
            <List
                className="widget-block--form--cart--container--popper--cartList--container"
                sx={{ minWidth: 300, maxWidth: { xs: 300, md: 400 }, py: 0 }}>
                {
                    data &&
                        data.length > 0 ? (
                        data.map((item, key) => (
                            <Box key={key}>
                                <ListItem sx={{ paddingLeft: 1, paddingRight: 1, py: 2, borderBottom: "1px solid #ddd", }}>
                                    <Box
                                        sx={{ width: 300 }}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        gap={1}
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            gap={1}
                                        >
                                            <IconButton
                                                onClick={() => removeFromCart(item.id)}
                                                size="large"
                                                aria-label="product delete"
                                                sx={{ padding: 0 }}
                                            >
                                                <DeleteTwoToneIcon sx={{ color: "grey.500" }} fontSize="medium" />
                                            </IconButton>
                                            <Box
                                                className="widget-block--form--cart--container--popper--cartList--container--header"
                                            >
                                                <Typography
                                                    variant="span"
                                                    className="widget-block--form--cart--container--popper--cartList--container--title"
                                                >
                                                    {item.package.name}
                                                    {
                                                        formattedTranslationValue(
                                                            item.package.translations,
                                                            'name',
                                                            language,
                                                        )
                                                    }

                                                </Typography>
                                                {(item.price && (
                                                    <Typography
                                                        variant="span"
                                                        className="widget-block--form--cart--container--popper--cartList--container--subtitle"
                                                    >
                                                        {formatCurrency(item.price, formCurrency)} x{" "}
                                                        {item.quantity} ={" "}
                                                        {formatCurrency(
                                                            item.price * item.quantity,
                                                            formCurrency
                                                        )}
                                                    </Typography>
                                                )) || (
                                                        <Typography variant="span" className="widget-block--form--cart--container--popper--cartList--container--fee">
                                                            <FormattedMessage id="free" />
                                                        </Typography>
                                                    )}

                                            </Box>
                                        </Box>
                                        <Box display="flex" gap={1}>
                                            <Box>
                                                <Increment amount={item.amount} period={item} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </ListItem>
                            </Box>
                        ))

                    ) : (
                        <Box>
                            <Box icon={false} severity="info" sx={{ color: "primary.main", textAlign: "center", fontSize: '0.875em', py: '0.875em' }}>
                                <FormattedMessage id="emptyCart" />
                            </Box>
                        </Box>
                    )
                }
            </List>

        </>

    )
}

export default CardList
