import dayjs from "./dayjs";
import { formattedTranslationValue } from "./formatted-translation-value";
import N from "./defaultValueTransformer";

export const defaultTimeZone = "Europe/Istanbul";
export const getLocalDateFormat = () => {
	const now = new Date(2013, 11, 31);
	let str = now.toLocaleDateString();
	str = str.replace("31", "DD");
	str = str.replace("12", "MM");
	str = str.replace("2013", "YYYY");
	return str;
};

export const getFormattedDate = N(
	(
		date,
		dateFormat,
		timeZone = defaultTimeZone // Varsayılan olarak Europe/Istanbul timezone
	) => {
		const format = dateFormat ?? getLocalDateFormat() ?? "DD/MM/YYYY";

		return dayjs(date).tz(timeZone).format(format);
	}
);

export const getFormattedDateTime = N(
	(date, dateFormat, timeFormat, timeZone = defaultTimeZone) => {
		// Hem tarih hem de saati göstermek için
		const formattedDate = dayjs(date)
			.tz(timeZone ?? defaultTimeZone)
			.format(dateFormat ?? getLocalDateFormat() ?? "DD/MM/YYYY");

		// Eğer timeFormat belirtilmişse, saati de ekleyelim
		const formattedTime = dayjs(date)
			.tz(timeZone ?? defaultTimeZone)
			.format(timeFormat ?? "HH:mm");
		return `${formattedDate} ${formattedTime}`; // Tarih ve saati birleştir
	}
);

export const getFormattedDay = N(
	(date, timeZone = defaultTimeZone, locale = "tr") => {
		dayjs.locale(locale);

		return dayjs(date).tz(timeZone).format("DD MMMM");
	}
);

export const getFormattedTagString = N(
	(tag, timeZone = defaultTimeZone, locale = "tr") => {
		return `${tag?.name} ${
			!!tag?.activeDate
				? `(${getFormattedDay(tag.activeDate, timeZone, locale)})`
				: ""
		}`;
	}
);

export const getFormattedPackageString = (pkg, i18n) =>
	`${
		pkg.parent
			? `${formattedTranslationValue(
					pkg.parent.translations,
					"name",
					i18n
			  )} ${formattedTranslationValue(pkg.translations, "name", i18n)}`
			: `${formattedTranslationValue(pkg.translations, "name", i18n)}`
	}`;

// UTC'den timezone'a göre günü formatlıyoruz
export const formatUTCToDay = N(
	(date, timeZone = defaultTimeZone, locale = "tr") => {
		dayjs.locale(locale);
		return dayjs.utc(date).tz(timeZone).format("dddd");
	}
);

// Tarihi gün numarasına çeviriyoruz
export const formatDateToDayNumber = N((date, timeZone = defaultTimeZone) => {
	return dayjs(date).tz(timeZone).format("DD");
});

export const formatToUtcTime = N((isoDate) => {
	if (!isoDate) return null;
	return dayjs.utc(isoDate).format("HH:mm");
});

export const convertTimeToDayjs = N(
	(timeString, timeZone = defaultTimeZone) => {
		if (!timeString) return null;
		const today = dayjs().format("YYYY-MM-DD");
		const datetime = `${today}T${timeString}Z`;

		return dayjs.utc(datetime).tz(timeZone);
	}
);
