const stripHtml = (html) => {
	if (!html) {
		return "";
	}

	if (html === "<p><br></p>") {
		return "";
	}
	if (typeof document === "undefined") {
		return html.replace(/<[^>]*>/g, "").trim();
	}

	const tempDiv = document.createElement("div");
	tempDiv.innerHTML = html;
	return (tempDiv.textContent || tempDiv.innerText || "").trim();
};

export const isHTMLEmpty = (value) => {
	const stripped = stripHtml(value);
	return !stripped || stripped.length === 0;
};
