import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getSpeakersDetail = createAsyncThunk(
	"speaker/detail",
	async ({ id, organiserID, eventID, language }) => {
		const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
		const REACT_APP_ENV = process.env.REACT_APP_ENV;
		const REACT_APP_API_URL_TEST = process.env.REACT_APP_API_URL_TEST;

		try {
			const url =
				REACT_APP_ENV === "prod" ? REACT_APP_API_URL : REACT_APP_API_URL_TEST;
			const res = await axios({
				method: "get",
				url: `${url}/api/speaker/public/getById/${organiserID}/${eventID}/${id}/${language}`,

				headers: {
					"Content-Type": "application/json",
				},
			});
			return res.data;
		} catch (error) {
			throw new Error("Bir Hata Oluştu: Details speakers");
		}
	}
);

export const getPrograms = createAsyncThunk(
	"program/all",
	async ({ organiserID, eventID, language }) => {
		const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
		const REACT_APP_ENV = process.env.REACT_APP_ENV;
		const REACT_APP_API_URL_TEST = process.env.REACT_APP_API_URL_TEST;
		try {
			const url =
				REACT_APP_ENV === "prod" ? REACT_APP_API_URL : REACT_APP_API_URL_TEST;

			const res = await axios({
				method: "get",
				url: `${url}/api/program/public/${organiserID}/${eventID}/${language}`,
				headers: {
					"Content-Type": "application/json",
				},
			});

			return res.data;
		} catch (error) {
			throw new Error("Bir Hata Oluştu: Program Api Error");
		}
	}
);

export const getEvent = createAsyncThunk(
	"program/event",
	async ({ eventID }) => {
		const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
		const REACT_APP_ENV = process.env.REACT_APP_ENV;
		const REACT_APP_API_URL_TEST = process.env.REACT_APP_API_URL_TEST;

		try {
			const url =
				REACT_APP_ENV === "prod" ? REACT_APP_API_URL : REACT_APP_API_URL_TEST;

			const response = await axios({
				method: "get",
				url: `${url}/api/event/public/${eventID}`,
				headers: {
					"Content-Type": "application/json",
				},
			});
			return response?.data;
		} catch (error) {
			throw new Error("Bir Hata Oluştu: Event Api Error");
		}
	}
);

const initialState = {
	error: null,
	loading: null,
	speaker: null,
	program: null,
	event: null,
};

export const programSlice = createSlice({
	name: "speaker",
	initialState,
	reducers: {
		emptySpeaker: (state) => {
			state.speaker = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSpeakersDetail.pending, (state) => {
				state.loading = true;
				state.speaker = null;
			})
			.addCase(getSpeakersDetail.fulfilled, (state, actions) => {
				state.speaker = actions.payload;
				state.loading = false;
			})
			.addCase(getSpeakersDetail.rejected, (state, actions) => {
				state.loading = false;
				state.speaker = null;
			})

			// Program
			.addCase(getPrograms.pending, (state) => {
				state.loading = true;
			})
			.addCase(getPrograms.fulfilled, (state, action) => {
				state.loading = false;
				state.program = action.payload;
			})
			.addCase(getPrograms.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
				state.program = [];
			})

			// evenet detaylarını getirme
			.addCase(getEvent.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getEvent.fulfilled, (state, action) => {
				state.loading = null;
				state.error = null;
				state.event = action.payload;
			})
			.addCase(getEvent.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySpeaker } = programSlice.actions;
export default programSlice.reducer;
