import { getLocale } from "./getLocale";

const formatCurrency = (value, currency, intl = null, free = false) => {
	if ((!currency || free) && intl) return intl.formatMessage({ id: "free" });
	return new Intl.NumberFormat(getLocale(), {
		style: "currency",
		currency: currency,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(value);
};

export default formatCurrency;
