import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { MutatingDots } from "react-loader-spinner";

const FaqWidget = ({ language, eventID, organiserID, color, displayTitle }) => {
	const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
	const REACT_APP_ENV = process.env.REACT_APP_ENV;
	const REACT_APP_API_URL_TEST = process.env.REACT_APP_API_URL_TEST;

	const [faq, setFaq] = useState([]);
	const [loading, setLoading] = useState(true);
	const [expanded, setExpanded] = useState(null); // Başlangıç değeri null

	useEffect(() => {
		if (faq.length > 0) {
			setExpanded(faq[0].id); // FAQ yüklendiğinde ilk öğeyi aç
		}
	}, [faq]); // faq değiştiğinde çalışsın

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	useEffect(() => {
		const url =
			REACT_APP_ENV === "prod" ? REACT_APP_API_URL : REACT_APP_API_URL_TEST;
		axios({
			method: "get",
			url: `${url}/api/faq/public/${organiserID}/${eventID}/${language}`,
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				setFaq(response.data || []);
			})
			.catch((error) => {})
			.finally(() => {
				setLoading(false);
			});
	}, [
		eventID,
		organiserID,
		REACT_APP_ENV,
		REACT_APP_API_URL,
		REACT_APP_API_URL_TEST,
		language,
	]);

	if (loading) {
		return (
			<Box className="widget-spinner">
				<MutatingDots
					visible={true}
					height="100"
					width="100"
					color="#4fa94d"
					secondaryColor="#4fa94d"
					radius="12.5"
					ariaLabel="mutating-dots-loading"
				/>
			</Box>
		);
	}

	return (
		<Box
			className="widget-block"
			style={{
				"--color": color,
			}}
		>
			{displayTitle === "true" && (
				<Box>
					<Typography
						variant="h2"
						component="h2"
						className="widget-block--header-title"
					>
						<FormattedMessage id="faq" />
					</Typography>
				</Box>
			)}

			<Box className="widget-block--faq">
				{!loading &&
					faq.length > 0 &&
					faq.map((item) => (
						<Accordion
							key={item.id}
							expanded={expanded === item.id}
							onChange={handleChange(item.id)}
							className="widget-block--faq--container"
						>
							<AccordionSummary
								expandIcon={expanded === item.id ? <RemoveIcon /> : <AddIcon />}
								sx={{
									backgroundColor: "#e0e0e0",
								}}
							>
								<Typography>{item.question}</Typography>
							</AccordionSummary>
							<AccordionDetails className="widget-block--faq--content-container">
								<Box
									className="widget-block--faq--content-container--content"
									dangerouslySetInnerHTML={{ __html: item.answer }}
								/>
							</AccordionDetails>
						</Accordion>
					))}
			</Box>
		</Box>
	);
};

export default FaqWidget;
