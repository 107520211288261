import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { MutatingDots } from "react-loader-spinner";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkIcon from "@mui/icons-material/Link";
import { cleanEmptyHTMLTags } from "./hooks/cleanEmptyHTMLTags";
import { useDispatch, useSelector } from "react-redux";
import { getSpeakers } from "./store/slices/speakers";

const SpeakersWidget = ({
	language,
	eventID,
	organiserID,
	color,
	displayTitle,
	topics,
}) => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const {
		speakers: _speakers,
		loading,
		error,
	} = useSelector((state) => state.speakers);
	const speakers = _speakers?.[topics];

	const [searchTerm, setSearchTerm] = useState("");

	const [open, setOpen] = useState(false);
	const [selectedSpeaker, setSelectedSpeaker] = useState([]);

	useEffect(() => {
		dispatch(
			getSpeakers({
				organiserID: organiserID,
				eventID: eventID,
				language: language,
				topics: topics,
			})
		);
	}, [eventID, organiserID, language, topics, dispatch]);

	const handleSearch = (event) => {
		setSearchTerm(event.target.value);
	};

	const filterSpeakers = (Array.isArray(speakers) ? speakers : []).filter(
		(speaker) =>
			(speaker.name || "").toLowerCase().includes(searchTerm.toLowerCase())
	);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setSelectedSpeaker(null);
	};
	if (loading || error) {
		return (
			<Box className="widget-spinner">
				<MutatingDots
					visible={true}
					height="100"
					width="100"
					color="#4fa94d"
					secondaryColor="#4fa94d"
					radius="12.5"
					ariaLabel="mutating-dots-loading"
					wrapperStyle={{}}
					wrapperClass=""
				/>
			</Box>
		);
	}

	return (
		<Box
			className="widget-block"
			style={{
				"--color": color,
			}}
		>
			{displayTitle === "true" && (
				<Box>
					<Typography
						variant="h2"
						component="h2"
						className="widget-block--header-title"
					>
						<FormattedMessage id="speaker" />
					</Typography>
				</Box>
			)}

			<Box className="widget-block--search-container">
				<Paper
					component="form"
					sx={{
						display: "flex",
						alignItems: "center",
						width: "100%",
						borderRadius: "0.8em",
					}}
				>
					<InputBase
						sx={{
							ml: 1,
							flex: 1,
							borderRadius: "0.8em",
							fontSize: "0.875em",
							paddingLeft: "0.5em",
						}}
						placeholder={intl.formatMessage({ id: "searchSpeaker" })}
						onChange={handleSearch}
						value={searchTerm}
					/>

					{searchTerm ? (
						<IconButton
							type="button"
							sx={{ p: "10px" }}
							aria-label="search"
							onClick={() => setSearchTerm("")}
						>
							<HighlightOffIcon />
						</IconButton>
					) : (
						<IconButton type="button" sx={{ p: "10px" }} aria-label="search">
							<SearchIcon />
						</IconButton>
					)}
				</Paper>
			</Box>
			{!loading && filterSpeakers.length > 0 ? (
				<Box className="widget-block--speakers">
					{filterSpeakers.map((item, index) => (
						<Box
							key={index}
							className="widget-block--speakers--item"
							onClick={() => {
								setSelectedSpeaker(item);
								handleOpen();
							}}
						>
							<Box className="widget-block--speakers--item--image">
								<img src={item?.speakerImagePath} alt={item?.name} />
							</Box>
							<Box className="widget-block--speakers--item--header">
								<Box
									className="widget-block--speakers--item--header--name"
									dangerouslySetInnerHTML={{ __html: item?.name }}
								/>
								<Box
									className="widget-block--speakers--item--header--title"
									dangerouslySetInnerHTML={{ __html: item?.title }}
								/>
								<Box
									className="widget-block--speakers--item--header--companyName"
									dangerouslySetInnerHTML={{
										__html: item?.companyName,
									}}
								/>
							</Box>
						</Box>
					))}
				</Box>
			) : (
				<Box className="widget-block--search-not">
					<Alert severity="info">
						<FormattedMessage id="notFoundSpeaker" />
					</Alert>
				</Box>
			)}
			{open && (
				<Modal open={open} aria-labelledby="modal-title" onClose={handleClose}>
					<Box className="widget-block--speakers--modal">
						<Box
							sx={{ textAlign: "center", borderBottom: ".5px solid #d6d9d9" }}
						>
							<IconButton
								onClick={handleClose}
								sx={{
									position: "absolute",
									top: 8,
									right: 8,
									cursor: "pointer",
								}}
							>
								<CloseIcon />
							</IconButton>
							<Typography
								variant="h6"
								sx={{ fontWeight: "bold", pb: "1em", color: color }}
							>
								<FormattedMessage id="speakerDetail" />
							</Typography>
						</Box>
						<Box className="widget-block--speakers--modal--speaker--container">
							<Box className="widget-block--speakers--modal--speaker--header">
								<Avatar
									alt="Remy Sharp"
									src={selectedSpeaker?.speakerImagePath}
									className="widget-block--speakers--modal--speaker--header--avatar"
								/>
								<Box>
									<Typography
										variant="h5"
										className="widget-block--speakers--modal--speaker--header--name"
									>
										{selectedSpeaker?.name}
									</Typography>
									<Typography
										variant="body2"
										color="textSecondary"
										sx={{ textAlign: "center" }}
										dangerouslySetInnerHTML={{ __html: selectedSpeaker?.title }}
									/>
								</Box>
								<Box
									className="widget-block--speakers--modal--speaker--header--companyName"
									dangerouslySetInnerHTML={{
										__html: selectedSpeaker?.companyName,
									}}
								/>
								<Box className="widget-block--speakers--modal--speaker--header--social-media">
									{selectedSpeaker.socialMedia?.linkend && (
										<IconButton
											component="a"
											href={selectedSpeaker.socialMedia?.linkend}
											target="_blank"
											rel="noopener noreferrer"
											aria-label="LinkedIn"
										>
											<LinkedInIcon />
										</IconButton>
									)}
									{selectedSpeaker.socialMedia?.twitter && (
										<IconButton
											component="a"
											href={selectedSpeaker.socialMedia?.twitter}
											target="_blank"
											rel="noopener noreferrer"
											aria-label="X (formerly Twitter)"
										>
											<XIcon />
										</IconButton>
									)}

									{selectedSpeaker.socialMedia?.instagram && (
										<IconButton
											component="a"
											href={selectedSpeaker.socialMedia?.instagram}
											target="_blank"
											rel="noopener noreferrer"
											aria-label="Instagram"
										>
											<InstagramIcon />
										</IconButton>
									)}

									{selectedSpeaker.socialMedia?.facebook && (
										<IconButton
											component="a"
											href={selectedSpeaker.socialMedia?.facebook}
											target="_blank"
											rel="noopener noreferrer"
											aria-label="Facebook"
										>
											<FacebookIcon />
										</IconButton>
									)}

									{selectedSpeaker.socialMedia?.youtube && (
										<IconButton
											component="a"
											href={selectedSpeaker.socialMedia?.youtube}
											target="_blank"
											rel="noopener noreferrer"
											aria-label="YouTube"
										>
											<YouTubeIcon />
										</IconButton>
									)}

									{selectedSpeaker.socialMedia?.website && (
										<IconButton
											component="a"
											href={selectedSpeaker.socialMedia?.website}
											target="_blank"
											rel="noopener noreferrer"
											aria-label="YouTube"
										>
											<LinkIcon />
										</IconButton>
									)}
								</Box>
							</Box>
							<Box className="widget-block--speakers--modal--speaker--bio">
								<Box
									sx={{
										marginTop: 2,
										textAlign: "left",
										width: "100%",
										overflowY: "auto",
										fontFamily: "'Lato', sans-serif",
										fontSize: "0.875em",
										color: "#818181",
									}}
									dangerouslySetInnerHTML={{
										__html: cleanEmptyHTMLTags(selectedSpeaker?.biography),
									}}
								/>
							</Box>
						</Box>
					</Box>
				</Modal>
			)}
		</Box>
	);
};

export default SpeakersWidget;
